import { title } from "process"
import React from "react"
import { useEffect, useState } from "react"
import { IError, Order } from '../../types.ts';

export const PayForm = () => {
    const [error, setError] = useState<IError>();
    const [order, setOrder] = useState<Order>();
    const [orderId, setOrderId] = useState<string>();
    const [success, setSucces] = useState<boolean>();
    const [nopay, setNoPay] = useState<boolean>();

    useEffect(() => {
        let url = new URL(window.location);
        let orderId = url.searchParams.get("orderId");
        let successId = url.searchParams.get("success");

        if (successId) {
            setSucces(true);
            return;
        }

        if (orderId) {
            fetch(`/order/${orderId}`)
                .then(res => res.json())
                .then(data => {
                    if (data && data.status != 'no_order') {
                        setOrderId(orderId);
                        setOrder(new Order(data));
                    } else {
                        setNoPay(true);
                    }
                })
        } else {
            setNoPay(true);
        }
    }, [])

    const handleClick = (event) => {
        event.preventDefault()
        let body = JSON.stringify({
            orderId: orderId
        })
        console.log(body)
        fetch('/pay', {
            method: 'post',
            body: body,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json())
        .then(data => {
            if (data.pay) {
                window.location = data.pay
            } else {
                console.log(data)
            }
        })
    }

    return (
        <div className="form__content">
            { nopay && <div className="nopay">
                <h2>Нет счета для оплаты.</h2>
            </div> }
            { success && <div className="success">
                <h2 style={{ 'marginBottom': '0px' }}>Оплата успешно выполнена!</h2>
                <p style={{ 'marginTop': '5px', 'opacity': '0.5' }}>Платеж будет обработан в ближайшие минуты.</p>
            </div> }
            { orderId && <div className="form">
                <div className="name">
                    <h3 style={{ 'marginBottom': '0px' }}>{order?.title}</h3>
                    <p style={{ 'marginTop': '5px', 'opacity': '0.5' }}>{order?.description}</p>
                </div>
                {error?.message && <div className="errorbox">
                    <span>{error?.message}</span>
                </div>}
                {order?.items && <div className="positions">
                    <ul>
                    {order?.items?.map((v,i) => {
                        return <li>{order?.items.length > 1 && <div style={{'paddingRight': '10px'}}>{i+1}.</div>}<div> {v.name}</div><div style={{'marginLeft': 'auto'}}>{v.count}{v.measure}</div><div>{v.price} {v.currency?.name}</div></li>
                    })}
                    </ul>
                </div>}
                {order?.fields && <div className="fields">
                    {
                        order?.fields?.map((v) => {
                            return <div className="field">
                                <input type={v.type} placeholder={v.name} required={v.required} />
                            </div>
                        })
                    }
                </div>}
                <div className="total"><h3>{order?.amount} {order?.currency?.name}</h3></div>
                <div className="buttons">
                    <button onClick={handleClick}>Оплатить</button>
                </div>
            </div>}
            { orderId && <div className="form__under">
                <div className="info">
                    <p className="primary">ООО "ИНТЕЛКАПИТАЛ"</p>
                    <p className="secondary">ИНН 1841065840 / ОГРН 1161832075822</p>
                    <p className="minor">услуги оказываются на основании <a href="https://rvspace.ru/pages/requisite.html">оферты</a></p>
                </div>
            </div>}
        </div>
    )
}