import { PayForm } from "../components/PayForm/PayForm.tsx"

export const Index = () => {
    return [
        <div className="title">
            <h5>RVSPACE</h5>
        </div>,
        <PayForm />,
        <div className="footer">
            <a href="tel:+ 7 3412 223-100">+ 7 3412 223-100</a>
            <a href="mailto:sale@rvspace.ru">sale@rvspace.ru</a>
        </div>
    ]
}