
type ErrorFields = {
    name: string,
    message: string
}

interface IError {
    message: string,
    errorFields: ErrorFields[] | null
}

type Currency = {
    code: number,
    character: string,
    name: string
}

interface IItems {
    name: string,
    price: number,
    count: number,
    measure: string,
    currency: Currency | null
}

interface IFields {
    code: string,
    required: true,
    type: string,
    name: string,
    value: any
}

interface IOrder {
    title: string,
    description: string,
    items: IItems[],
    fields: IFields[] | null,
    amount: number,
    currency: Currency | null
}

class Order implements IOrder {
    title: string
    description: string
    items: IItems[]
    fields: IFields[]
    amount: number
    currency: Currency | null

    constructor(data) {
        this.title = data.title;
        this.description = data.description;
        this.items = data.items;
        this.fields = data.fields;
        this.amount = data.amount;
        this.currency = data.currency;
    }
}

export {IError, Order}